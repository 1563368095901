// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // apiUrl:'https://apistaging.bonbell.net/api/',
  apiUrl:'https://api.test.bonbell.net/api/',
  dashboardUrl:'https://vendor.test.bonbell.net/',
  firebase: {
    projectId: 'bonbell-ed9c0',
    appId: '1:991451441304:web:8fb98740dd00119d7d88ed',
    databaseURL: 'https://bonbell-ed9c0-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'bonbell-ed9c0.appspot.com',
    apiKey: 'AIzaSyBdFZoBEJ1EfDa0oMktfFzocdOHdnrjvo4',
    authDomain: 'bonbell-ed9c0.firebaseapp.com',
    messagingSenderId: '991451441304',
    measurementId: 'G-7BNFQ94TYG',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
